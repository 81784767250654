<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Harga Lab</b> yang ada di Klinik IONA
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/lab-procedure/add')"
      >Tambah</b-button>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div class="row align-items-center justify-content-end mt-n3">
              <div class="col-md-3 col-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Berdasar Nama"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
            <b-table
              striped
              hover
              class="mt-3"
              responsive
              style="white-space: nowrap"
              :items="items"
              :fields="fields"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>
              <template #cell(price)="data">
                Rp {{ parseInt(data.item.price).toLocaleString('id-ID') }}
              </template>
              <template #cell(doctor_commission)="data">
                Rp {{ data.item.doctor_commission.toLocaleString('id-ID') }}
              </template>
              <template #cell(hospital_income)="data">
                Rp {{ data.item.hospital_income.toLocaleString('id-ID') }}
              </template>
              <template #cell(actions)='data'>
                <b-button
                  size="sm"
                  class="mr-1 btn-primary"
                  variant="primary"
                  v-b-tooltip.hover
                  title="Komisi Dokter"
                  @click="$router.push({ path: '/lab-procedure/add-doctor-commision/' + data.item.id })"
                ><i class="fas fa-user-md px-0"></i></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  @click="$router.push({ path: '/lab-procedure/detail/' + data.item.id })"
                ><i class="fas fa-eye px-0"></i></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-if="manipulateBtn == true"
                  @click="$router.push({ path: '/lab-procedure/edit/' + data.item.id })"
                ><i class="fas fa-edit px-0"></i></b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-if="manipulateBtn == true"
                  @click="btnDeleteOnClick(data.item.id)"
                ><i class="fas fa-trash px-0"></i></b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card
  },

  data() {
    return {
      // Filter
      filter: {
        name: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "lab_category_name",
          label: "Kategori Lab",
          sortable: true,
        },
        {
          key: "doctor_commission",
          label: "Komisi Dokter",
          sortable: true,
        },
        {
          key: "hospital_income",
          label: "Pendapatan Rumah Sakit",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // access management
      manipulateBtn:false,
    }
  },

  methods: {

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.lastCurrentPage = page;
      this.pagination()
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`
      let response = await module.paginate('lab-procedures', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      
      let a, b
      b = this.perPage * (this.currentPage - 1) + 1  
      for(a = 0; a < response.data.length; a++){
        response.data[a].number = b + a
      }
      this.items = response.data
    },

    filterByName() {
      this.currentPage = 1
      if(this.filter.name == ""){
        this.currentPage = this.lastCurrentPage
      }
      this.pagination()
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('lab-procedures/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      console.log("looping")
      
      if(access_right[a] == "1102"){
        this.manipulateBtn = true
      }
      
    }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pemeriksaan Lab", },
      { title: "Harga Lab" },
    ])
    // Get Data
    this.pagination()
    this.setActiveMenu()
  },

}
</script>

<style>
</style>